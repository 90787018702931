// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { contentfulImageLoader } from "@/src/contentful/contentfulImageLoader";
import { createBackendApolloClient } from "@/src/lib/Client";
import fetchBrowseGrid from "@/src/lib/server-side-fetch/fetchBrowseGrid";
import fetchContentfulBanners from "@/src/lib/server-side-fetch/fetchContentfulBanners";
import fetchContentfulHomePage2 from "@/src/lib/server-side-fetch/fetchContentfulHomePage2";
import fetchContentfulMagnetPageCollection from "@/src/lib/server-side-fetch/fetchContentfulMagnetPageCollection";
import fetchHomeWithNoTopics from "@/src/lib/server-side-fetch/fetchHomeWithNoTopics";
import { SitewideProps, addSiteWideProps } from "@/src/lib/sitewideProps";
import NewUserHero from "@/src/website/client/components/home/NewUserHeroPhase2";
import SimpleLayout from "@/src/website/client/components/layouts/SimpleLayout";
import {
  MAIN_IMAGE_HEIGHT,
  MAIN_IMAGE_WIDTH,
} from "@/src/website/shared/Activity";
import { Box, Image, ImageProps, Theme, Typography } from "@outschool/backpack";
import {
  HOMEPAGE_PREVIEW_IMAGE,
  imageUrl as getImageUrl,
} from "@outschool/filestack-urls";
import { ContentfulMagnetPage } from "@outschool/gql-backend-generated";
import { HomeWithNoTopicsQuery } from "@outschool/gql-frontend-generated";
import {
  BASE_LOCALE,
  EAST_ASIA_LOCALES,
  SUPPORTED_LOCALES,
  useLocale,
  useTranslation,
} from "@outschool/localization";
import { pathToUrl } from "@outschool/routes";
import {
  BrowseGrid,
  CustomerServiceContacts,
  EnglishClassesByLevelCategories,
  FeaturedCategoriesHomePage,
  FeaturedTeacherRow,
  GradientWrapper,
  InTheNews,
  MagnetPagesCarousel,
  OutschoolInAction,
  QuizHero,
  TeacherStories,
  TestimonialsCarousel,
  makeCtaFromContentfulBanner,
} from "@outschool/ui-components-website";
import {
  Container,
  TrackingPage,
  TrackingPageSection,
} from "@outschool/ui-legacy-component-library";
import { DeviceType, DeviceTypeContext } from "@outschool/ui-utils";
import { GetStaticPropsResult } from "next";
import Head from "next/head";
import React, { Fragment } from "react";

import JoinAndWelcomeButton from "../../website/client/components/JoinAndWelcomeButton";
import {
  NextImage,
  SsrImageProps,
  WithNextImageProp,
} from "../../website/client/components/lib/SsrImage";
import { Header } from "../../website/client/components/nav/Header";

import type { HomePageContent } from "@/src/lib/server-side-fetch/fetchContentfulHomePage2";

import type { ContentfulBannerItem } from "@/src/lib/server-side-fetch/fetchContentfulBanners";

import type { ApolloClient } from "@outschool/ui-apollo";

type HomePageProps = {
  deviceType: DeviceType;
  heroSlides: HomePageContent["heroSlides"];
  homeQueryWithNoTopics: HomeWithNoTopicsQuery["featured"];
  locale?: string;
  magnetArticles: ContentfulMagnetPage[];
  subjectKeywordLinks: Record<string, any>;
  valueProps: HomePageContent["valueProps"];
  visualLinkGrids: HomePageContent["visualLinkGrids"];
  contentfulBanners: ContentfulBannerItem;
};

export default function HomePage({
  deviceType,
  heroSlides,
  homeQueryWithNoTopics,
  magnetArticles,
  subjectKeywordLinks,
  valueProps,
  visualLinkGrids,
  contentfulBanners,
  ...props
}: HomePageProps & SitewideProps) {
  const device = { deviceType };
  return (
    <DeviceTypeContext.Provider value={device}>
      <HomePageMetaTags />
      <main>
        <NewUserHome
          heroSlides={heroSlides}
          homeQueryWithNoTopics={homeQueryWithNoTopics}
          magnetArticles={magnetArticles}
          subjectKeywordLinks={subjectKeywordLinks}
          valueProps={valueProps}
          visualLinkGrids={visualLinkGrids}
          contentfulBanners={contentfulBanners}
          {...props}
        />
      </main>
    </DeviceTypeContext.Provider>
  );
}

interface NewUserHomeProps {
  heroSlides: HomePageContent["heroSlides"];
  homeQueryWithNoTopics: HomeWithNoTopicsQuery["featured"];
  magnetArticles: ContentfulMagnetPage[];
  subjectKeywordLinks: Record<string, any>;
  valueProps: HomePageContent["valueProps"];
  visualLinkGrids: HomePageContent["visualLinkGrids"];
  contentfulBanners: any;
}

const contentfulBannerPlacement = "LoggedOutHome Top";

function NewUserHome({
  heroSlides,
  valueProps,
  homeQueryWithNoTopics,
  magnetArticles,
  subjectKeywordLinks,
  visualLinkGrids,
  contentfulBanners,
  ...props
}: NewUserHomeProps & SitewideProps) {
  const { t } = useTranslation("ssr-client\\pages\\HomePage\\Treatment");
  const kidOccupations = heroSlides.map(slide => `${slide.title}.`);
  const kidImageUrls = heroSlides.map(slide => slide.image.url);

  const { experimentAssignments } = props;
  const locale = useLocale();
  const isEmbeddedQuizLocale = locale === BASE_LOCALE;
  const isEmbeddedQuizExperiment =
    isEmbeddedQuizLocale &&
    experimentAssignments["onboardingEmbeddedQuiz2"] &&
    experimentAssignments["onboardingEmbeddedQuiz2"] === "treatment";

  // NOTE: In the SSR website, we chose to only show the first banner and to ignore any
  // filtering that is happening on the bannerCampaign to reduce scope.
  //
  // More context in the slack thread: https://outschool.slack.com/archives/C052J5GMS1G/p1697129624414189
  const makeBanner = contentfulBanners?.bannersCollection?.items.map(item =>
    makeCtaFromContentfulBanner(
      item,
      contentfulBannerPlacement,
      true,
      // Not supporting experiments in SSR website for now, until its needed.
      undefined
    )
  );
  const firstBanner = makeBanner[0];
  const Banner = firstBanner ? (
    <firstBanner.Component
      renderImage={(props: SsrImageProps) => {
        return (
          <Box
            sx={{ position: "relative", minWidth: "140px", aspectRatio: "1" }}
          >
            <Image<WithNextImageProp>
              alt={props.alt}
              {...props}
              sizes="(max-width: 768px) 100vw, 25vw"
              nextImage={{
                layout: "fill",
                loader: contentfulImageLoader,
              }}
            />
          </Box>
        );
      }}
    />
  ) : null;

  return (
    <TrackingPage
      impressionEventName={"Home Page Impression"}
      uniqueId="homePage"
      name="homePage"
      useLinkTracking
    >
      {setImpressionNode => (
        <SimpleLayout
          ref={node => {
            setImpressionNode(node);
          }}
          allowCategoriesNavbar={false}
          headerComponent={
            isEmbeddedQuizExperiment ? (
              <Header
                allowCategoriesNavbar={false}
                showSearchBar={false}
                sx={{
                  background: "none",
                  boxShadow: "none",
                }}
                joinButton={
                  <JoinAndWelcomeButton
                    variant="text"
                    sx={(theme: Theme) => ({
                      border: `2px solid ${theme.palette.primary.main}`,
                      color: theme.palette.primary.dark,
                    })}
                  >{t`Join`}</JoinAndWelcomeButton>
                }
              />
            ) : null
          }
          {...props}
        >
          {isEmbeddedQuizExperiment ? (
            <QuizHero
              renderOptionImages={[
                <Image<WithNextImageProp>
                  key="hero-image-1"
                  src="https://cdn.filestackcontent.com/output=format:webp/SQ9wtzomQwOjBC8RETCv"
                  alt={t`A child looking at a screen and smiling`}
                  nextImage={{
                    layout: "fill",
                    priority: true,
                  }}
                />,
                <Image<WithNextImageProp>
                  key="hero-image-2"
                  src="https://cdn.filestackcontent.com/output=format:webp/GZn0hboFQSO5ej2CMl8A"
                  alt={t`A child and adult holding hands`}
                  nextImage={{
                    layout: "fill",
                    priority: true,
                  }}
                />,
                <Image<WithNextImageProp>
                  key="hero-image-3"
                  src="https://cdn.filestackcontent.com/output=format:webp/tMLmYwSBQIaBi3j9d3KQ"
                  alt={t`A child crafting`}
                  nextImage={{
                    layout: "fill",
                    priority: true,
                  }}
                />,
              ]}
            />
          ) : (
            <NewUserHero
              kidOccupations={kidOccupations}
              kidImageUrls={kidImageUrls}
            />
          )}
          <GradientWrapper>
            {EAST_ASIA_LOCALES.includes(locale) && (
              <EnglishClassesByLevelCategories />
            )}
            <ContentfulThinValueProps valueProps={valueProps} />
            <FeaturedCategoriesHomePage
              items={visualLinkGrids}
              isSSR={true}
              Banner={Banner}
              renderImage={(imageProps: ImageProps, nextProps: NextImage) => {
                return (
                  <Image<WithNextImageProp>
                    alt={imageProps.alt}
                    {...imageProps}
                    nextImage={{
                      loader: contentfulImageLoader,
                      ...nextProps,
                    }}
                  />
                );
              }}
            />
          </GradientWrapper>
          <Box
            sx={(theme: Theme) => ({
              [theme.breakpoints.down("md")]: { my: "50px" },
              mt: "110px",
              mb: "10px",
            })}
          >
            {EAST_ASIA_LOCALES.includes(locale) ? (
              <>
                <CustomerServiceContacts />
              </>
            ) : (
              <>
                <TestimonialsCarousel />
              </>
            )}
          </Box>
          <TrackingPageSection
            name="feature_teacher_row"
            uniqueId="feature_teacher_row"
          >
            {setImpressionNode => (
              <FeaturedTeacherRow
                ref={setImpressionNode}
                teachers={homeQueryWithNoTopics.popularTeachers}
                title={t("10,000+ experienced teachers")}
              />
            )}
          </TrackingPageSection>
          <TrackingPageSection name="in_the_news" uniqueId="in_the_news_row">
            {setImpressionNode => (
              <Container>
                <InTheNews stories={TeacherStories} ref={setImpressionNode} />
              </Container>
            )}
          </TrackingPageSection>
          <TrackingPageSection
            name="outschool_in_action_row"
            uniqueId="outschool_in_action_row"
          >
            {(setImpressionNode, trackTouch) => (
              <Container>
                <OutschoolInAction
                  ref={setImpressionNode}
                  trackTouch={trackTouch}
                />
              </Container>
            )}
          </TrackingPageSection>
          <MagnetPagesCarousel
            articles={magnetArticles}
            carouselSx={{
              maxWidth: "100%",
            }}
            isSSR
          />
          <BrowseGrid
            title={t("Classes by subject")}
            showTopBoxes={false}
            subjectKeywordLinksData={subjectKeywordLinks}
          />
        </SimpleLayout>
      )}
    </TrackingPage>
  );
}

function ContentfulThinValueProps({
  valueProps,
}: {
  valueProps: HomePageContent["valueProps"];
}) {
  return (
    <Container>
      <Box
        flex
        sx={(theme: Theme) => ({
          gap: "32px",
          justifyContent: "center",
          paddingTop: "24px",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            gap: "16px",
          },
        })}
      >
        {valueProps.map(vp => (
          <Fragment key={vp.title}>
            <ValueProp prop={vp} />
          </Fragment>
        ))}
      </Box>
    </Container>
  );
}

function ValueProp({ prop }: { prop: HomePageContent["valueProps"][number] }) {
  return (
    <Box
      flex
      sx={(theme: Theme) => ({
        gap: "14px",
        [theme.breakpoints.down("md")]: {
          justifyContent: "center",
        },
      })}
    >
      <Image<WithNextImageProp>
        alt={`illustrative icon for the title ${prop.title}`}
        src={prop.icon.url}
        width={24}
        height={24}
        nextImage={{ loader: contentfulImageLoader }}
      />
      <Typography variant="h6">{prop.title}</Typography>
    </Box>
  );
}

export function HomePageMetaTags() {
  const { t } = useTranslation("ssr-client\\pages\\HomePage\\Treatment");
  const canonicalUrl = pathToUrl("/");
  const title = t(
    "Get affordable online classes and tutoring for kids at Outschool"
  );
  const description = t(
    "Find the perfect academic resource for your kids. Try online classes and tutoring from Outschool’s vetted 5-star teachers. No contracts or sales calls"
  );
  let hrefLangUrls = [];
  for (let supportedLocale of SUPPORTED_LOCALES) {
    let langUrl = pathToUrl("/", supportedLocale);
    // @ts-ignore
    hrefLangUrls.push({
      rel: "alternate",
      hreflang: supportedLocale,
      href: langUrl,
    });
  }

  // both dimensions must be > 200 for Facebook to accept as
  // link preview
  const previewImageWidth = 2 * MAIN_IMAGE_WIDTH;
  const previewImageHeight = 2 * MAIN_IMAGE_HEIGHT;

  const imageUrl = getImageUrl(HOMEPAGE_PREVIEW_IMAGE, {
    w: previewImageWidth,
    h: previewImageHeight,
    fit: "clip",
  });

  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="description" content={description.slice(0, 155)} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonicalUrl} />
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:image" content={imageUrl} />
      {hrefLangUrls.map((link, index) => (
        <link
          key={index}
          rel={link.rel}
          hrefLang={link.hreflang}
          href={link.href}
        />
      ))}
    </Head>
  );
}

export function getServerSideProps({ req, params, locale }) {
  const client = createBackendApolloClient(locale);
  return addSiteWideProps({
    req,
    fn: () => getHomePageProps({ ...params, client, locale }),
    locale,
  });
}

async function getHomePageProps({
  client,
  deviceType,
  locale,
}: {
  client: ApolloClient<any>;
  deviceType: DeviceType;
  locale: string;
}): Promise<GetStaticPropsResult<HomePageProps>> {
  const [
    homeQueryWithNoTopics,
    magnetArticles,
    homePageContent,
    subjectKeywordLinks,
    banners,
  ] = await Promise.all([
    fetchHomeWithNoTopics({ client }),
    fetchContentfulMagnetPageCollection({ client }),
    fetchContentfulHomePage2({ client, locale }),
    fetchBrowseGrid({ client }),
    fetchContentfulBanners({
      client,
      locale,
      placement: contentfulBannerPlacement,
    }),
  ]);

  const { heroSlides, valueProps, visualLinkGrids } = homePageContent;

  const props: HomePageProps = {
    deviceType,
    heroSlides,
    homeQueryWithNoTopics,
    locale,
    magnetArticles: magnetArticles,
    valueProps,
    visualLinkGrids,
    subjectKeywordLinks: subjectKeywordLinks,
    contentfulBanners: banners,
  };

  return {
    props,
  };
}
